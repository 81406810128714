import { Color } from '@libero/types/Color';

export const hexToRgba = (color: Color, alpha: number): string => {
  const [red, green, blue] = color.match(/\w\w/g)?.map((x) => parseInt(x, 16)) || [0, 0, 0];
  return `rgba(${red}, ${green}, ${blue}, ${alpha})`;
};

export const getColor = (color: string): Color => {
  switch (color) {
    case 'gray':
      return Color.gray500;
    case 'dark-gray':
      return Color.gray800;
    case 'primary':
      return Color.primary700;
    case 'secondary':
      return Color.secondary600;
    case 'success':
      return Color.successDark;
    case 'warning':
      return Color.warningBase;
    case 'error':
      return Color.errorDark;
    default:
      return color as unknown as Color;
  }
};
