<script lang="ts" setup>
import { InboxIcon } from '@heroicons/vue/24/solid';
import { NoteMentionNotification } from '@libero/api-client/notification/types';
import Typography from '@libero/ui-framework/Typography/Typography.vue';
import { lowerCase } from 'lodash';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

import BaseNotification, { Props } from './BaseNotification.vue';

const props = defineProps<Props>();

const { t } = useI18n();

const notification = computed(() => props.notification as NoteMentionNotification);

const routeName = computed(() => {
  if (!notification.value) {
    return;
  }

  switch (notification.value.data.model_name) {
    case 'ticket':
      return 'ticket.show.notes';
    case 'container':
      return 'container.notes';
    default:
      return '';
  }
});
</script>

<template>
  <BaseNotification
    v-bind="props"
    :notification="notification"
    :to="{
      name: routeName,
      params: { id: notification.data.model_id },
    }"
  >
    <template #icon>
      <InboxIcon />
    </template>

    <Typography>
      {{ t('notification.edit-note-mention') }}

      <Typography isDark bold>
        {{ lowerCase(t(`${notification.data.model_name}.${notification.data.model_name}`)) }}
      </Typography>
    </Typography>
  </BaseNotification>
</template>
