<script setup lang="ts">
import { CheckCircleIcon } from '@heroicons/vue/24/solid';
import { notificationApi } from '@libero/api-client/notification/api';
import { Notification } from '@libero/api-client/notification/types';
import ResourceDropdown from '@libero/cityview/modules/resource/components/ResourceDropdown/ResourceDropdown.vue';
import DropdownAction from '@libero/ui-framework/Dropdown/DropdownAction.vue';
import { useMutation, useQueryClient } from '@tanstack/vue-query';
import { useI18n } from 'vue-i18n';

interface Props {
  notification: Notification;
}

const props = defineProps<Props>();

const queryClient = useQueryClient();
const { t } = useI18n();

const { mutateAsync: handleMarkAsRead } = useMutation({
  mutationFn: notificationApi.markAsRead(props.notification.id),
  onSuccess: () => {
    queryClient.invalidateQueries({ queryKey: ['notification.index'] });
  },
});
</script>

<template>
  <ResourceDropdown
    :item="notification"
    :resourceApi="notificationApi"
    :deleteInvalidateKeys="[['notification.index']]"
  >
    <DropdownAction v-if="!notification.read_at" :onClick="handleMarkAsRead">
      <CheckCircleIcon class="dropdown-action-icon" />
      {{ t('notification.mark-as-read') }}
    </DropdownAction>
  </ResourceDropdown>
</template>
