import { generateFileRoutes } from '@libero/cityview/modules/file/generate-routes';
import { generateFolderRoutes } from '@libero/cityview/modules/folder/generate-routes';
import { generateTicketRoutes } from '@libero/cityview/modules/ticket/generate-routes';
import type { RouteRecordRaw } from 'vue-router';

import { generateContainerRoutes } from './generate-routes';

export const routes: RouteRecordRaw[] = [
  {
    path: 'container',
    name: 'container.index',
    component: () => import('./views/pages/Index.vue'),
    children: [
      ...generateContainerRoutes('container'),
      ...generateTicketRoutes('container'),
      ...generateFileRoutes('container'),
      ...generateFolderRoutes('container'),
    ],
    meta: {
      title: 'Objecten',
      permission: 'container.index',
    },
  },
  {
    path: 'container/mutate',
    name: 'container.mutate',
    component: () => import('./views/pages/Mutate.vue'),
    props: true,
    meta: {
      title: 'Objecten muteren',
      permission: 'entity.update',
    },
  },
];
