<script lang="ts" setup>
import { CheckIcon } from '@heroicons/vue/24/solid';
import { ReplicationNotification } from '@libero/api-client/notification/types';
import { useMutateStore } from '@libero/hooks/store/useMutateStore';
import Button from '@libero/ui-framework/Button/Button.vue';
import Cluster from '@libero/ui-framework/Cluster/Cluster.vue';
import Stack from '@libero/ui-framework/Stack/Stack.vue';
import Typography from '@libero/ui-framework/Typography/Typography.vue';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';

import { Props } from './BaseNotification.vue';
import BaseNotification from './BaseNotification.vue';

const props = defineProps<Props>();

const { t } = useI18n();
const router = useRouter();
const mutateStore = useMutateStore();

const notification = computed(() => props.notification as ReplicationNotification);

const handleMutate = () => {
  mutateStore.setIds(notification.value.data.container_ids);
  router.push({ name: `container.mutate` });
};
</script>

<template>
  <BaseNotification v-bind="props" :notification="notification">
    <template #icon>
      <CheckIcon />
    </template>

    <Stack :gap="0.25">
      <Typography isDark>
        {{ t('notification.replication-done') }}
      </Typography>

      <Cluster>
        <Button size="sm" :onClick="handleMutate">
          {{ t('notification.open-mutation') }}
        </Button>
      </Cluster>
    </Stack>
  </BaseNotification>
</template>
