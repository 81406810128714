import type { RouteRecordRaw } from 'vue-router';

import PersonalSettingsLayout from './views/layouts/PersonalSettingsLayout/PersonalSettingsLayout.vue';

export const routes: RouteRecordRaw[] = [
  {
    path: '/personal-settings',
    component: PersonalSettingsLayout,
    children: [
      {
        path: 'profile',
        name: 'personal-settings.profile',
        component: () => import('./views/pages/Profile.vue'),
        meta: {
          title: 'Persoonlijke instellingen',
        },
      },
      {
        path: 'preferences',
        name: 'personal-settings.preferences',
        component: () => import('./views/pages/Preferences.vue'),
        meta: {
          title: 'Persoonlijke instellingen',
        },
      },
      {
        path: 'notifications',
        name: 'personal-settings.notifications',
        component: () => import('./views/pages/Notifications.vue'),
        meta: {
          title: 'Persoonlijke instellingen',
        },
      },
      {
        path: 'security',
        name: 'personal-settings.security',
        component: () => import('./views/pages/Security.vue'),
        meta: {
          title: 'Persoonlijke instellingen',
        },
      },
      {
        path: '',
        redirect: { name: 'personal-settings.profile' },
      },
    ],
  },
];
