<script setup lang="ts">
import { CheckCircleIcon, ExclamationCircleIcon } from '@heroicons/vue/24/solid';
import { Color } from '@libero/types/Color';
import Cluster from '@libero/ui-framework/Cluster/Cluster.vue';
import Icon from '@libero/ui-framework/Icon/Icon.vue';
import Typography from '@libero/ui-framework/Typography/Typography.vue';
import { Progress } from 'ant-design-vue';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

interface Props {
  percentage: number;
  isFailed?: boolean;
}

const props = defineProps<Props>();

const { t } = useI18n();

const status = computed(() => {
  if (props.isFailed) return 'exception';
  if (props.percentage === 100) return 'success';
  return 'active';
});
</script>

<template>
  <Cluster alignItems="center" :gap="0.5">
    <Progress :percent="percentage" :status="status" size="small" />

    <Icon v-if="isFailed" :color="Color.errorBase">
      <ExclamationCircleIcon />
    </Icon>

    <Icon v-else-if="percentage === 100" :color="Color.successBase">
      <CheckCircleIcon />
    </Icon>

    <Typography v-else-if="percentage === 0" :color="Color.gray500" hasNoWrap>
      {{ t('in-queue') }}
    </Typography>

    <Typography v-else :color="Color.gray500" hasNoWrap>
      {{ `${percentage}%` }}
    </Typography>
  </Cluster>
</template>

<style lang="scss" scoped>
@import '@libero/ui-framework/Progress/Progress.scss';
</style>
