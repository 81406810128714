import { generateContainerRoutes } from '@libero/cityview/modules/container/generate-routes';
import { generateFileRoutes } from '@libero/cityview/modules/file/generate-routes';
import { generateOrganisationRoutes } from '@libero/cityview/modules/organisation/generate-routes';
import { generateRoleRoutes } from '@libero/cityview/modules/role/routes';
import { generateTicketRoutes } from '@libero/cityview/modules/ticket/generate-routes';
import { generateUserRoutes } from '@libero/cityview/modules/user/generate-routes';
import type { RouteRecordRaw } from 'vue-router';

export const routes: RouteRecordRaw[] = [
  {
    path: 'favorite',
    name: 'favorite.index',
    component: () => import('./views/pages/Index.vue'),
    children: [
      ...generateTicketRoutes('favorite'),
      ...generateContainerRoutes('favorite'),
      ...generateOrganisationRoutes('favorite'),
      ...generateUserRoutes('favorite'),
      ...generateRoleRoutes('favorite'),
      ...generateFileRoutes('favorite'),
    ],
    meta: {
      title: 'Favorieten',
    },
  },
];
