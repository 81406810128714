<script lang="ts" setup>
import { Notification } from '@libero/api-client/notification/types';
import { kebabCase } from 'lodash';
import { computed } from 'vue';

import EditNoteMention from './Adapters/EditNoteMention.vue';
import NewNoteMention from './Adapters/NewNoteMention.vue';
import ReplicationDone from './Adapters/ReplicationDone.vue';
import ReplicationFailed from './Adapters/ReplicationFailed.vue';
import ReplicationStarted from './Adapters/ReplicationStarted.vue';
import TicketActionOrganisation from './Adapters/TicketActionOrganisation.vue';
import TicketActionOwner from './Adapters/TicketActionOwner.vue';
import TicketHighPriority from './Adapters/TicketHighPriority.vue';

interface Props {
  notification: Notification;
  isToast?: boolean;
  onClose?: () => void;
}

const props = defineProps<Props>();

const type = computed(() =>
  kebabCase(props.notification.type.split('\\').at(-1)?.replace('Notification', '')),
);

const adapter = computed(() => {
  switch (type.value) {
    case 'ticket-action-owner':
      return TicketActionOwner;
    case 'ticket-action-organisation':
      return TicketActionOrganisation;
    case 'ticket-high-priority':
      return TicketHighPriority;
    case 'replication-started':
      return ReplicationStarted;
    case 'replication-done':
      return ReplicationDone;
    case 'replication-failed':
      return ReplicationFailed;
    case 'new-note-mention':
      return NewNoteMention;
    case 'edited-note-mention':
      return EditNoteMention;
    default:
      return undefined;
  }
});
</script>

<template>
  <component
    :is="adapter"
    v-if="adapter"
    :notification="notification"
    :isToast="isToast"
    :onClose="onClose"
  />
</template>
