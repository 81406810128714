import type { RouteRecordRaw } from 'vue-router';

import { generateContainerRoutes } from '../container/generate-routes';
import { generateFileRoutes } from '../file/generate-routes';
import { generateTicketRoutes } from '../ticket/generate-routes';

export const routes: RouteRecordRaw[] = [
  {
    path: 'dashboard',
    component: () => import('./views/layouts/DashboardsLayout/DashboardsLayout.vue'),
    children: [
      {
        path: 'present',
        name: 'dashboard.present',
        children: [
          ...generateContainerRoutes('dashboard'),
          ...generateTicketRoutes('dashboard'),
          ...generateFileRoutes('dashboard'),
        ],
        component: () => import('./views/pages/Present.vue'),
      },
      {
        path: 'performance',
        name: 'dashboard.performance',
        component: () => import('./views/pages/Performance.vue'),
      },
      {
        name: 'dashboard.index',
        path: '',
        redirect: { name: 'dashboard.present' },
      },
    ],
    meta: {
      title: 'Dashboard',
      permission: 'ticket.index',
    },
  },
];
