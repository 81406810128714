<script setup lang="ts">
import Header from '@libero/cityview/modules/app/views/segments/Header/Header.vue';
import Sidebar from '@libero/cityview/modules/app/views/segments/Sidebar/Sidebar.vue';
import { useUserStore } from '@libero/hooks/store/useUserStore';
import { ConfigProvider } from 'ant-design-vue';
import enUS from 'ant-design-vue/es/locale/en_US';
import nlNL from 'ant-design-vue/es/locale/nl_NL';
import { computed } from 'vue';
import { RouterView } from 'vue-router';

const userStore = useUserStore();

const locale = computed(() => {
  switch (userStore.user?.settings.locale) {
    case 'nl':
      return nlNL;
    case 'en':
      return enUS;
    default:
      return nlNL;
  }
});
</script>

<template>
  <ConfigProvider :locale="locale">
    <div class="app-layout">
      <Sidebar />

      <main class="app-layout-main">
        <Header />

        <div class="app-layout-main-content">
          <RouterView />
        </div>
      </main>
    </div>
  </ConfigProvider>
</template>

<style lang="scss" scoped>
@import '@libero/cityview/modules/app/views/layouts/AppLayout/AppLayout.scss';
</style>
