import { LoginResponse } from '@libero/api-client/auth/types';
import { jsonApi } from '@libero/utilities/api-client';

import { TwoFactorCodeRequest, TwoFactorVerifyRequest } from './types';

const twoFactor = jsonApi('api/auth/two-factor');

const send = async (): Promise<unknown> => {
  return twoFactor.put('send');
};

const enable = async (data: TwoFactorCodeRequest): Promise<unknown> => {
  return twoFactor.put('enable', data);
};

const disable = async (data: TwoFactorCodeRequest): Promise<unknown> => {
  return twoFactor.put('disable', data);
};

const verify = async (data: TwoFactorVerifyRequest): Promise<LoginResponse> => {
  return twoFactor.put('verify', data);
};

export const twoFactorApi = {
  send,
  enable,
  disable,
  verify,
};
