<script setup lang="ts">
import ConditionalWrapper from '@libero/ui-framework/ConditionalWrapper/ConditionalWrapper.vue';
import Scrollable from '@libero/ui-framework/Scrollable/Scrollable.vue';
import { classNames } from '@libero/utilities/class-names';
import { computed } from 'vue';

interface Props {
  spacing?: 'sm' | 'md' | 'lg';
  flex?: string;
  isDark?: boolean;
  isHeading?: boolean;
  isScrollable?: boolean;
  hasForcedTopShadow?: boolean;
  hasNoSpacing?: boolean;
  hasNoSpacingBottom?: boolean;
  hasNoBorder?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  spacing: 'md',
  flex: undefined,
});

const scrollableProps = computed(() => {
  if (props.isScrollable) {
    return {
      isAbsolute: true,
      hasForcedTopShadow: props.hasForcedTopShadow,
    };
  }

  return {};
});
</script>

<template>
  <div class="drawer-section" :class="classNames({ isDark, hasNoBorder })" :style="{ flex }">
    <ConditionalWrapper :component="Scrollable" :isWrapped="isScrollable" v-bind="scrollableProps">
      <div
        class="drawer-section-content"
        :class="classNames({ spacing, isHeading, isScrollable, hasNoSpacing, hasNoSpacingBottom })"
      >
        <slot />
      </div>
    </ConditionalWrapper>
  </div>
</template>

<style lang="scss" scoped>
@import '@libero/ui-framework/Drawer/DrawerSection.scss';
</style>
