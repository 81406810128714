<script setup lang="ts">
import { XMarkIcon } from '@heroicons/vue/24/solid';
import FadeMotion from '@libero/ui-framework/FadeMotion/FadeMotion.vue';
import IconButton from '@libero/ui-framework/IconButton/IconButton.vue';
import { useSidebarState } from '@libero/ui-framework/Sidebar/useSidebarState';
import { classNames } from '@libero/utilities/class-names';
import { clearAllBodyScrollLocks, disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { onUnmounted, ref, watch } from 'vue';

import { useDrawerCounter } from './useDrawerCounter';

interface Props {
  isOpen: boolean;
  width?: string;
  hasOffset?: boolean;
  isPersistent?: boolean;
  onClose: () => void;
}

const props = withDefaults(defineProps<Props>(), {
  width: '18rem',
});

const { collapseSidebar, expandSidebar } = useSidebarState();
const { increment, decrement, isOverlapping, isLast } = useDrawerCounter();

const drawer = ref<HTMLElement>();
const renderSlot = ref(false);

const handleKeyDown = (event: KeyboardEvent) => {
  if (event.key === 'Escape' && isLast.value) {
    props.onClose();
  }
};

const handleOpen = () => {
  increment();
  document.addEventListener('keydown', handleKeyDown);

  renderSlot.value = true;
  if (props.isPersistent) collapseSidebar();

  setTimeout(() => {
    if (drawer.value) disableBodyScroll(drawer.value);
  }, 200);
};

const handleClose = () => {
  decrement();
  document.removeEventListener('keydown', handleKeyDown);
  if (props.isPersistent) expandSidebar();

  if (!isOverlapping.value) {
    setTimeout(() => {
      if (drawer.value) enableBodyScroll(drawer.value);
    }, 200);
  }
};

watch(
  () => props.isOpen,
  () => {
    if (props.isOpen) {
      handleOpen();
    } else {
      handleClose();
    }
  },
);

onUnmounted(() => {
  expandSidebar();
  clearAllBodyScrollLocks();
});
</script>

<template>
  <teleport :to="isPersistent ? '#persistent-drawer' : '#drawers'">
    <FadeMotion>
      <div v-if="!isPersistent && isOpen" class="drawer-backdrop" :onClick="onClose" />
    </FadeMotion>

    <div
      class="drawer"
      :class="classNames({ isPersistent, hasOffset })"
      :style="{ width: isOpen ? width : undefined }"
    >
      <IconButton
        v-if="isOpen"
        class="drawer-close"
        size="sm"
        appearance="link"
        color="secondary"
        :onClick="onClose"
      >
        <XMarkIcon />
      </IconButton>

      <div class="drawer-content" :style="{ width }">
        <slot v-if="renderSlot" />
      </div>
    </div>
  </teleport>
</template>

<style lang="scss" scoped>
@import '@libero/ui-framework/Drawer/Drawer.scss';
</style>
