<script lang="ts" setup>
import { SearchItem } from '@libero/api-client/scout/types';
import { Color } from '@libero/types/Color';
import Clickable from '@libero/ui-framework/Clickable/Clickable.vue';
import Cluster from '@libero/ui-framework/Cluster/Cluster.vue';
import Icon from '@libero/ui-framework/Icon/Icon.vue';
import Stack from '@libero/ui-framework/Stack/Stack.vue';
import Typography from '@libero/ui-framework/Typography/Typography.vue';
import { classNames } from '@libero/utilities/class-names';
import { getResourceShowRoute } from '@libero/utilities/resource';
import { getResourceIcon, getResourceName, getResourceSubject } from '@libero/utilities/resource';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';

interface Props {
  item: SearchItem;
  isActive: boolean;
  onClick: () => void;
}

const props = defineProps<Props>();

const router = useRouter();
const { t } = useI18n();

const resourceName = computed(() => getResourceName(props.item.resource_type));
const icon = computed(() => getResourceIcon(resourceName.value));
const subject = computed(() => getResourceSubject(resourceName.value, props.item.resource));

const handleClick = () => {
  const route = getResourceShowRoute(resourceName.value, props.item.resource);
  if (route) router.push(route);
  props.onClick();
};
</script>

<template>
  <Clickable class="search-item" :class="classNames({ isActive })" :onClick="handleClick">
    <Cluster :gap="0.75">
      <Icon size="lg" :color="Color.gray400">
        <component :is="icon" />
      </Icon>

      <Stack :gap="0">
        <Typography type="heading" size="xs" bold>
          {{ subject }}
        </Typography>

        <Typography size="sm">
          {{ t(`${resourceName}.${resourceName}`) }}
        </Typography>
      </Stack>
    </Cluster>
  </Clickable>
</template>

<style lang="scss" scoped>
@import '@libero/cityview/modules/search/components/SearchItem/SearchItem.scss';
</style>
