import { type Ref, ref } from 'vue';

const SIDEBAR_EXPANDED = 'sidebar-expanded';
const SIDEBAR_EXPANDED_DESKTOP_UP = 'sidebar-expanded-desktop-up';

const isDesktopUp = window.matchMedia('(min-width: 1200px)').matches;
const localStorageKey = isDesktopUp ? SIDEBAR_EXPANDED_DESKTOP_UP : SIDEBAR_EXPANDED;

const getIsExpanded = () => {
  const item = localStorage.getItem(localStorageKey);

  if (item !== null) {
    return localStorage.getItem(localStorageKey) === 'true';
  }

  return isDesktopUp;
};

const isExpanded = ref(getIsExpanded());

interface UseSidebarState {
  isExpanded: Ref<boolean>;
  expandSidebar: () => void;
  collapseSidebar: () => void;
  toggleSidebarSave: () => void;
}

export const useSidebarState = (): UseSidebarState => {
  const expandSidebar = () => {
    if (getIsExpanded()) {
      isExpanded.value = true;
    }
  };

  const collapseSidebar = () => {
    if (getIsExpanded()) {
      isExpanded.value = false;
    }
  };

  const toggleSidebarSave = () => {
    isExpanded.value = !isExpanded.value;
    localStorage.setItem(localStorageKey, isExpanded.value.toString());
  };

  return {
    isExpanded,
    expandSidebar,
    collapseSidebar,
    toggleSidebarSave,
  };
};
