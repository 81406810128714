<script setup lang="ts">
import { ArrowUturnLeftIcon } from '@heroicons/vue/24/solid';
import Button from '@libero/ui-framework/Button/Button.vue';
import Cluster from '@libero/ui-framework/Cluster/Cluster.vue';
import Container from '@libero/ui-framework/Container/Container.vue';
import SecondaryNav from '@libero/ui-framework/SecondaryNav/SecondaryNav.vue';
import SecondaryNavItem from '@libero/ui-framework/SecondaryNav/SecondaryNavItem.vue';
import Stack from '@libero/ui-framework/Stack/Stack.vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
</script>

<template>
  <Container hasOffset>
    <Cluster flex="1" alignItems="stretch">
      <SecondaryNav>
        <Button appearance="clear" :to="{ name: 'contract.index' }">
          <template #icon>
            <ArrowUturnLeftIcon />
          </template>

          {{ t('contract.back') }}
        </Button>

        <SecondaryNavItem :to="{ name: 'contract-item.index' }">
          {{ t('contract-item.index') }}
        </SecondaryNavItem>

        <SecondaryNavItem :to="{ name: 'price-list.index' }">
          {{ t('price-list.index') }}
        </SecondaryNavItem>

        <SecondaryNavItem :to="{ name: 'term-sheet.index' }">
          {{ t('term-sheet.index') }}
        </SecondaryNavItem>

        <SecondaryNavItem :to="{ name: 'lead-time.index' }">
          {{ t('lead-time.index') }}
        </SecondaryNavItem>

        <SecondaryNavItem :to="{ name: 'contract.log' }">
          {{ t('contract.log') }}
        </SecondaryNavItem>
      </SecondaryNav>

      <Stack width="100%">
        <RouterView />
      </Stack>
    </Cluster>
  </Container>
</template>
