<script setup lang="ts">
import { PlusIcon } from '@heroicons/vue/24/solid';
import { useTenantStore } from '@libero/hooks/store/useTenantStore';
import { useThemeStore } from '@libero/hooks/store/useThemeStore';
import { useUserStore } from '@libero/hooks/store/useUserStore';
import Cluster from '@libero/ui-framework/Cluster/Cluster.vue';
import IconButton from '@libero/ui-framework/IconButton/IconButton.vue';
import Tooltip from '@libero/ui-framework/Tooltip/Tooltip.vue';
import { useI18n } from 'vue-i18n';

import Theme from './Theme.vue';

interface Props {
  expanded: boolean;
}

defineProps<Props>();

const { t } = useI18n();
const tenantStore = useTenantStore();
const themeStore = useThemeStore();
const { hasPermission } = useUserStore();
</script>

<template>
  <div class="themes">
    <Cluster :gap="0.25" shouldWrap>
      <Theme
        v-for="theme in themeStore.themes"
        :key="theme.id"
        :expanded="expanded"
        :theme="theme"
      />

      <Tooltip
        v-if="hasPermission('theme.index') && !tenantStore.tenant?.has_all_themes"
        :content="t('theme.add')"
        :placement="expanded ? 'top' : 'right'"
      >
        <IconButton size="xs" appearance="link" color="secondary" :to="{ name: 'theme.index' }">
          <PlusIcon />
        </IconButton>
      </Tooltip>
    </Cluster>
  </div>
</template>

<style lang="scss" scoped>
@import '@libero/organisms/Themes/Themes.scss';
</style>
```
