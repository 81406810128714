import { FunctionalComponent } from 'vue';

import CubeMap from './cube-map.svg';
import InboxMap from './inbox-map.svg';
import InboxPlus from './inbox-plus.svg';
import LayoutHorizontalMap from './layout-horizontal-map.svg';
import LayoutHorizontalTable from './layout-horizontal-table.svg';
import LayoutMap from './layout-map.svg';
import LayoutTable from './layout-table.svg';
import LayoutVerticalMap from './layout-vertical-map.svg';
import LayoutVerticalTable from './layout-vertical-table.svg';
import Merge from './merge.svg';
import PersonInCircle from './person-in-circle.svg';
import Product from './product.svg';

export const CubeMapIcon = CubeMap as unknown as FunctionalComponent;
export const InboxMapIcon = InboxMap as unknown as FunctionalComponent;
export const InboxPlusIcon = InboxPlus as unknown as FunctionalComponent;
export const LayoutHorizontalMapIcon = LayoutHorizontalMap as unknown as FunctionalComponent;
export const LayoutHorizontalTableIcon = LayoutHorizontalTable as unknown as FunctionalComponent;
export const LayoutMapIcon = LayoutMap as unknown as FunctionalComponent;
export const LayoutTableIcon = LayoutTable as unknown as FunctionalComponent;
export const LayoutVerticalMapIcon = LayoutVerticalMap as unknown as FunctionalComponent;
export const LayoutVerticalTableIcon = LayoutVerticalTable as unknown as FunctionalComponent;
export const ProductIcon = Product as unknown as FunctionalComponent;
export const MergeIcon = Merge as unknown as FunctionalComponent;
export const PersonInCircleIcon = PersonInCircle as unknown as FunctionalComponent;
