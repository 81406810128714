<script setup lang="ts">
import type { Color } from '@libero/types/Color';
import { classNames } from '@libero/utilities/class-names';
import { hexToRgba } from '@libero/utilities/color';
import { computed } from 'vue';

interface Props {
  verticalSpacing?: number;
  horizontalSpacing?: number;
  offsetLeft?: number;
  backgroundColor?: Color;
  backgroundOpacity?: number;
  borderColor?: Color;
  borderWidth?: number;
  borderPosition?: ('top' | 'bottom' | 'left' | 'right')[];
  borderRadius?: 'xs' | 'sm' | 'md' | 'lg';
  height?: string;
  maxWidth?: string;
  opacity?: number;
  hasNoSpacing?: boolean;
  hasHover?: boolean;
  hasShadow?: boolean;
  hasBorder?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  offsetLeft: undefined,
  verticalSpacing: 1,
  horizontalSpacing: 1.5,
  backgroundColor: undefined,
  backgroundOpacity: 1,
  borderColor: undefined,
  borderWidth: 1,
  borderPosition: () => ['top', 'bottom', 'left', 'right'],
  borderRadius: undefined,
  height: undefined,
  maxWidth: undefined,
  opacity: undefined,
});

const borderWidthStyle = computed(() => ({
  borderTopWidth: props.borderPosition?.includes('top') ? `${props.borderWidth}px` : 0,
  borderBottomWidth: props.borderPosition?.includes('bottom') ? `${props.borderWidth}px` : 0,
  borderLeftWidth: props.borderPosition?.includes('left') ? `${props.borderWidth}px` : 0,
  borderRightWidth: props.borderPosition?.includes('right') ? `${props.borderWidth}px` : 0,
}));
</script>

<template>
  <div
    class="block"
    :class="classNames({ borderRadius, hasHover, hasShadow })"
    :style="{
      marginLeft: offsetLeft && `${offsetLeft}rem`,
      paddingTop: hasNoSpacing ? undefined : `${verticalSpacing}rem`,
      paddingBottom: hasNoSpacing ? undefined : `${verticalSpacing}rem`,
      paddingLeft: hasNoSpacing ? undefined : `${horizontalSpacing}rem`,
      paddingRight: hasNoSpacing ? undefined : `${horizontalSpacing}rem`,
      backgroundColor: backgroundColor && hexToRgba(backgroundColor, backgroundOpacity),
      borderStyle: hasBorder ? 'solid' : undefined,
      borderColor,
      height,
      maxWidth,
      opacity,
      ...borderWidthStyle,
    }"
  >
    <slot />
  </div>
</template>

<style lang="scss" scoped>
@import '@libero/ui-framework/Block/Block.scss';
</style>
