<script setup lang="ts">
import { useUserStore } from '@libero/hooks/store/useUserStore';
import Cluster from '@libero/ui-framework/Cluster/Cluster.vue';
import Container from '@libero/ui-framework/Container/Container.vue';
import SecondaryNav from '@libero/ui-framework/SecondaryNav/SecondaryNav.vue';
import SecondaryNavItem from '@libero/ui-framework/SecondaryNav/SecondaryNavItem.vue';
import Stack from '@libero/ui-framework/Stack/Stack.vue';
import Typography from '@libero/ui-framework/Typography/Typography.vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
const { hasPermission } = useUserStore();
</script>

<template>
  <Container hasOffset>
    <Cluster flex="1" alignItems="stretch">
      <SecondaryNav hasOffset>
        <Stack>
          <Stack :gap="0">
            <Typography class="navigation__title" size="sm" mb="050" uppercase semiBold>
              {{ t('general') }}
            </Typography>

            <SecondaryNavItem
              :to="{ name: 'user.index' }"
              :isDisabled="!hasPermission('user.index')"
            >
              {{ t('user.index') }}
            </SecondaryNavItem>

            <SecondaryNavItem
              :to="{ name: 'organisation.index' }"
              :isDisabled="!hasPermission('organisation.index')"
            >
              {{ t('organisation.index') }}
            </SecondaryNavItem>

            <SecondaryNavItem
              :to="{ name: 'role.index' }"
              :isDisabled="!hasPermission('role.index')"
            >
              {{ t('role.index') }}
            </SecondaryNavItem>

            <SecondaryNavItem
              :to="{ name: 'integration.index' }"
              :isDisabled="!hasPermission('integration.index')"
            >
              {{ t('integration.index') }}
            </SecondaryNavItem>

            <SecondaryNavItem
              :to="{ name: 'classification.index' }"
              :isDisabled="!hasPermission('classification.index')"
            >
              {{ t('classification.index') }}
            </SecondaryNavItem>
          </Stack>

          <Stack :gap="0">
            <Typography class="navigation__title" size="sm" mb="050" uppercase semiBold>
              {{ t('ticket.tickets') }}
            </Typography>

            <SecondaryNavItem
              :to="{ name: 'ticket-subject.index' }"
              :isDisabled="!hasPermission('ticket-subject.index')"
            >
              {{ t('ticket-subject.ticket-subjects') }}
            </SecondaryNavItem>

            <SecondaryNavItem
              :to="{ name: 'cause.index' }"
              :isDisabled="!hasPermission('cause.index')"
            >
              {{ t('cause.causes') }}
            </SecondaryNavItem>
          </Stack>
        </Stack>
      </SecondaryNav>

      <Stack width="100%">
        <RouterView />
      </Stack>
    </Cluster>
  </Container>
</template>
