import boxes from '@libero/theme/icons/themes/boxes.svg';
import buildings from '@libero/theme/icons/themes/buildings.svg';
import cables_and_pipelines from '@libero/theme/icons/themes/cables_and_pipelines.svg';
import cameras from '@libero/theme/icons/themes/cameras.svg';
import energy from '@libero/theme/icons/themes/energy.svg';
import furniture from '@libero/theme/icons/themes/furniture.svg';
import parking from '@libero/theme/icons/themes/parking.svg';
import public_lighting from '@libero/theme/icons/themes/public_lighting.svg';
import sensors from '@libero/theme/icons/themes/sensors.svg';
import technical_structures from '@libero/theme/icons/themes/technical_structures.svg';
import traffic from '@libero/theme/icons/themes/traffic.svg';
import water from '@libero/theme/icons/themes/water.svg';
import { FunctionalComponent } from 'vue';

export const getThemeIcon = (key: string): FunctionalComponent | undefined => {
  switch (key) {
    case 'boxes':
      return boxes as unknown as FunctionalComponent;
    case 'buildings':
      return buildings as unknown as FunctionalComponent;
    case 'cables_and_pipelines':
      return cables_and_pipelines as unknown as FunctionalComponent;
    case 'cameras':
      return cameras as unknown as FunctionalComponent;
    case 'energy':
      return energy as unknown as FunctionalComponent;
    case 'furniture':
      return furniture as unknown as FunctionalComponent;
    case 'parking':
      return parking as unknown as FunctionalComponent;
    case 'public_lighting':
      return public_lighting as unknown as FunctionalComponent;
    case 'sensors':
      return sensors as unknown as FunctionalComponent;
    case 'technical_structures':
      return technical_structures as unknown as FunctionalComponent;
    case 'traffic':
      return traffic as unknown as FunctionalComponent;
    case 'water':
      return water as unknown as FunctionalComponent;

    default:
      undefined;
  }
};
