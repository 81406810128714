<script lang="ts" setup>
import { BellIcon } from '@heroicons/vue/24/solid';
import { notificationApi } from '@libero/api-client/notification/api';
import { Notification as NotificationType } from '@libero/api-client/notification/types';
import Notification from '@libero/cityview/modules/notification/components/Notification/Notification.vue';
import NotificationContainer from '@libero/cityview/modules/notification/components/NotificationContainer/NotificationContainer.vue';
import { useUserStore } from '@libero/hooks/store/useUserStore';
import Button from '@libero/ui-framework/Button/Button.vue';
import Cluster from '@libero/ui-framework/Cluster/Cluster.vue';
import Drawer from '@libero/ui-framework/Drawer/Drawer.vue';
import DrawerSection from '@libero/ui-framework/Drawer/DrawerSection.vue';
import IconButton from '@libero/ui-framework/IconButton/IconButton.vue';
import Tab from '@libero/ui-framework/Tabs/Tab.vue';
import Tabs from '@libero/ui-framework/Tabs/Tabs.vue';
import Tooltip from '@libero/ui-framework/Tooltip/Tooltip.vue';
import Typography from '@libero/ui-framework/Typography/Typography.vue';
import { UnsubscribeCallback } from '@libero/utilities/echo-client';
import { useMutation, useQuery, useQueryClient } from '@tanstack/vue-query';
import { watch } from 'vue';
import { onUnmounted } from 'vue';
import { computed, onMounted } from 'vue';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';

const queryClient = useQueryClient();
const userStore = useUserStore();
const { t } = useI18n();

const unsubscribe = ref<UnsubscribeCallback>();
const activeIds = ref<string[]>([]);
const isOpen = ref(false);
const shouldShowUnread = ref(true);

const toggleOpen = () => {
  isOpen.value = !isOpen.value;
};

const setShouldShowUnread = (value: boolean) => {
  shouldShowUnread.value = value;
};

const handleCloseNotification = (notificationId: string) => {
  activeIds.value = activeIds.value.filter((id) => id !== notificationId);
};

const { data: notifications } = useQuery({
  queryKey: ['notification.index'],
  queryFn: notificationApi.index,
});

const { mutateAsync: handleMarkAsRead } = useMutation({
  mutationFn: notificationApi.markAsRead(),
  onSuccess: () => {
    queryClient.invalidateQueries({ queryKey: ['notification.index'] });
  },
});

const allCount = computed(() => notifications.value?.length);

const unreadCount = computed(
  () => notifications.value?.filter((notification) => !notification.read_at).length || 0,
);

const filteredNotifications = computed(() => {
  if (shouldShowUnread.value) {
    return notifications.value?.filter((notification) => !notification.read_at);
  }

  return notifications.value;
});

const activeNotifications = computed(
  () => notifications.value?.filter((notification) => activeIds.value.includes(notification.id)),
);

const subscribe = () => {
  if (unsubscribe.value || !userStore.user?.has_notification_subscription) return;

  unsubscribe.value = notificationApi.subscribe(
    userStore.user.id,
    (notification: NotificationType) => {
      queryClient.setQueryData<NotificationType[]>(
        ['notification.index'],
        [notification, ...(notifications.value || [])],
      );

      if (notification.reset_type) {
        const idsToFilter = notifications.value
          ?.filter((n) => n.type === notification.reset_type)
          .map((n) => n.id);

        activeIds.value = activeIds.value.filter((id) => !idsToFilter?.includes(id));
      }

      activeIds.value.push(notification.id);
    },
  );
};

onMounted(subscribe);
onUnmounted(() => unsubscribe.value?.());

watch(userStore, subscribe);
</script>

<template>
  <Tooltip :content="t('notification.notifications')">
    <IconButton
      appearance="clear"
      color="secondary"
      :onClick="toggleOpen"
      :hasBadge="unreadCount > 0"
    >
      <BellIcon />
    </IconButton>
  </Tooltip>

  <Drawer :isOpen="isOpen" width="26rem" :onClose="toggleOpen">
    <DrawerSection isHeading hasNoSpacingBottom>
      <Typography tag="h3" type="heading" bold mb="025">
        {{ t('notification.notifications') }}
      </Typography>

      <Cluster alignItems="center" :gap="1.5">
        <Tabs>
          <Tab
            :isActive="!shouldShowUnread"
            :count="allCount"
            :onClick="() => setShouldShowUnread(false)"
          >
            {{ t('notification.all') }}
          </Tab>

          <Tab
            :isActive="shouldShowUnread"
            :count="unreadCount"
            :onClick="() => setShouldShowUnread(true)"
          >
            {{ t('notification.unread') }}
          </Tab>
        </Tabs>

        <Button appearance="clear" :onClick="handleMarkAsRead">
          {{ t('notification.mark-all-as-read') }}
        </Button>
      </Cluster>
    </DrawerSection>

    <DrawerSection flex="1" isScrollable hasForcedTopShadow hasNoSpacing>
      <Notification
        v-for="notification in filteredNotifications"
        :key="notification.id"
        :notification="notification"
      />
    </DrawerSection>
  </Drawer>

  <NotificationContainer
    v-if="activeNotifications"
    :notifications="activeNotifications"
    :onClose="handleCloseNotification"
  />
</template>
