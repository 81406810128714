import type { RouteRecordRaw } from 'vue-router';

export const routes: RouteRecordRaw[] = [
  {
    path: 'export',
    name: 'export.index',
    component: () => import('./views/pages/Index.vue'),
    meta: {
      title: 'Data instellingen',
      permission: 'export.index',
    },
  },
];
