import { computed, type Ref, ref } from 'vue';

interface UseDrawerCounter {
  increment: () => void;
  decrement: () => void;
  isOverlapping: Ref<boolean>;
  isLast: Ref<boolean>;
}

const count = ref(0);

export const useDrawerCounter = (): UseDrawerCounter => {
  const current = ref(count.value);

  const increment = () => {
    count.value++;
    current.value = count.value;
  };

  const decrement = () => {
    count.value--;
  };

  const isOverlapping = computed(() => current.value > 0);
  const isLast = computed(() => current.value === count.value);

  return {
    increment,
    decrement,
    isOverlapping,
    isLast,
  };
};
