<script setup lang="ts">
import Support from '@libero/cityview/modules/support/views/forms/Support.vue';
import Modal from '@libero/ui-framework/Modal/Modal.vue';
import ModalContent from '@libero/ui-framework/Modal/ModalContent.vue';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';

interface Props {
  onSuccess?: () => void;
}

defineProps<Props>();

const { t } = useI18n();

const open = ref(false);

const toggleOpen = () => {
  open.value = !open.value;
};
</script>

<template>
  <slot :openModal="toggleOpen" />

  <Modal
    width="42rem"
    :isOpen="open"
    :title="t('support.contact-support')"
    :footer="false"
    :onCancel="toggleOpen"
  >
    <ModalContent>
      <Support :onSuccess="onSuccess" :onClose="toggleOpen" />
    </ModalContent>
  </Modal>
</template>
