<script setup lang="ts">
import { Color } from '@libero/types/Color';
import Icon from '@libero/ui-framework/Icon/Icon.vue';
import { getThemeIcon } from '@libero/utilities/theme';
import { computed } from 'vue';

interface Props {
  theme: string;
  size?: 'md' | 'lg' | 'xl';
  color?: Color;
  isDark?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  size: 'md',
  color: undefined,
});

const color = computed(() => {
  if (props.color) {
    return props.color;
  }

  if (props.isDark) {
    return Color.primary100;
  }

  return Color.primary800;
});

const icon = computed(() => getThemeIcon(props.theme));
</script>

<template>
  <Icon :color="color" :size="size">
    <component :is="icon" />
  </Icon>
</template>

```
