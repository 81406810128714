import type { RouteRecordRaw } from 'vue-router';

export const routes: RouteRecordRaw[] = [
  {
    path: 'contract-item',
    name: 'contract-item.index',
    component: () => import('./views/pages/Index.vue'),
    children: [
      {
        path: 'create',
        name: 'contract-item.create',
        component: () => import('./views/modals/Create.vue'),
        meta: {
          delay: true,
          permission: 'contract.store',
        },
      },
      {
        path: ':id',
        component: () => import('./views/modals/Show.vue'),
        children: [
          {
            path: 'properties',
            name: 'contract-item.show',
            component: () => import('./views/tabs/Properties.vue'),
          },
          {
            path: 'properties/edit',
            name: 'contract-item.edit',
            component: () => import('./views/tabs/PropertiesEdit.vue'),
          },
          {
            path: 'log',
            name: 'contract-item.log',
            component: () => import('./views/tabs/Log.vue'),
          },
        ],
        meta: {
          delay: true,
          permission: 'contract.show',
        },
      },
    ],
    meta: {
      title: 'Systeem instellingen / Contracten',
      permission: 'contract.index',
    },
  },
];
