import { echo, UnsubscribeCallback } from '@libero/utilities/echo-client';

import type { ProgressStatus, ReplicationProgress } from './types';

const subscribe = (
  id: string,
  onMessage: (status: ProgressStatus) => void,
): UnsubscribeCallback => {
  const channel = echo.channel(`Progress.${id}`).listen('ProgressStatus', onMessage);

  return () => channel.stopListening('ExportStatus');
};

const subscribeContainerReplication = (
  userId: string,
  callback: (progress: ReplicationProgress) => void,
): UnsubscribeCallback => {
  const channel = echo
    .private(`App.Models.User.${userId}`)
    .listen('ContainerReplicationProgress', callback);

  return () => channel.stopListening('ContainerReplicationProgress');
};

export const progressApi = {
  name: 'progress',
  subscribe,
  subscribeContainerReplication,
};
