import { Column } from '@libero/api-client/column/types';
import { useLocalStorage } from '@libero/hooks/useLocalStorage';
import { defineStore } from 'pinia';
import { onMounted } from 'vue';
import { watch } from 'vue';
import { computed } from 'vue';
import { type Ref } from 'vue';
import { useRoute } from 'vue-router';

interface Store {
  ids: Ref<(string | number)[]>;
  setIds: (ids: (string | number)[]) => void;
  columns: Ref<Column[]>;
  setColumns: (columns: Column[]) => void;
  reset: () => void;
  isMutating: Ref<boolean>;
}

export const useMutateStore = defineStore('mutate', (): Store => {
  const route = useRoute();
  const [ids, setIds] = useLocalStorage<(string | number)[]>('ids', []);
  const [columns, setColumns] = useLocalStorage<Column[]>('columns', []);
  const isMutating = computed(() => route.name?.toString().endsWith('mutate') || false);

  const reset = () => {
    if (isMutating.value) return;

    setIds([]);
    setColumns([]);
  };

  watch(isMutating, reset);
  onMounted(reset);

  return {
    ids,
    setIds,
    columns,
    setColumns,
    reset,
    isMutating,
  };
});
