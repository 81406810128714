<script lang="ts" setup>
import { ArrowPathIcon } from '@heroicons/vue/24/solid';
import Cityview from '@libero/theme/logos/cityview.svg';
import Button from '@libero/ui-framework/Button/Button.vue';
import Stack from '@libero/ui-framework/Stack/Stack.vue';
import Typography from '@libero/ui-framework/Typography/Typography.vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
</script>

<template>
  <div class="maintenance-layout">
    <Stack :gap="2" alignItems="center">
      <Cityview width="300" />

      <Typography type="heading" size="xxxl" bold>
        {{ t('maintenance') }}
      </Typography>

      <Button size="lg" color="secondary" :to="{ name: 'dashboard.index' }">
        <template #icon>
          <ArrowPathIcon />
        </template>

        {{ t('reload') }}
      </Button>
    </Stack>
  </div>
</template>

<style lang="scss" scoped>
@import '@libero/cityview/modules/app/views/layouts/MaintenanceLayout/MaintenanceLayout.scss';
</style>
