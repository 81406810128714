import { RouteRecordRaw } from 'vue-router';

import SystemSettingsLayout from './views/layouts/SystemSettingsLayout/SystemSettingsLayout.vue';

const children = Object.values(
  import.meta.glob(
    `../(user|organisation|role|ticket-subject|cause|integration|classification)/routes.ts`,
    {
      eager: true,
    },
  ) as Record<string, { routes: RouteRecordRaw[] }>,
)
  .map((routes) => routes.routes)
  .flat();

export const routes: RouteRecordRaw[] = [
  {
    path: '/system-settings',
    component: SystemSettingsLayout,
    children: [
      ...children,
      {
        path: '',
        redirect: { name: 'user.index' },
      },
    ],
  },
];
