import AppLayout from '@libero/cityview/modules/app/views/layouts/AppLayout/AppLayout.vue';
import AuthLayout from '@libero/cityview/modules/app/views/layouts/AuthLayout/AuthLayout.vue';
import MaintenanceLayout from '@libero/cityview/modules/app/views/layouts/MaintenanceLayout/MaintenanceLayout.vue';
import { routes as authRoutes } from '@libero/cityview/modules/auth/routes';
import { invalidationHooks, useAuthStore } from '@libero/hooks/store/useAuthStore';
import { useTenantStore } from '@libero/hooks/store/useTenantStore';
import { useUserStore } from '@libero/hooks/store/useUserStore';
import { i18n } from '@libero/i18n/index';
import { afterResponseHooks } from '@libero/utilities/api-client';
import { defaultRouterOptions } from '@libero/utilities/routing';
import {
  createRouter,
  createWebHistory,
  RouteLocationNormalized,
  type RouteRecordRaw,
} from 'vue-router';

const routes = Object.values(
  import.meta.glob(
    `./modules/(energy|e-safety|sensor-registry|favorite|document|dashboard|container|map|theme|view|ticket|contract|export|system-settings|data-settings|personal-settings)/routes.ts`,
    {
      eager: true,
    },
  ) as Record<string, { routes: RouteRecordRaw[] }>,
)
  .map((routes) => routes.routes)
  .flat();

const protectedRoute = () => {
  const authStore = useAuthStore();

  if (!authStore.authenticated) {
    return { name: 'auth.login' };
  }
};

export const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/auth',
      component: AuthLayout,
      children: authRoutes,
    },
    {
      path: '/maintenance',
      name: 'maintenance',
      component: MaintenanceLayout,
    },
    {
      path: '/',
      component: AppLayout,
      beforeEnter: [protectedRoute],
      children: [
        ...routes,
        {
          path: '/',
          redirect: { name: 'dashboard.index' },
        },
      ],
    },
    {
      path: '/:pathMatch(.*)*',
      redirect: { name: 'dashboard.index' },
    },
  ],
  ...defaultRouterOptions,
});

router.beforeEach(async (to, from, next) => {
  const { authenticated, setRedirectTo } = useAuthStore();
  const { tenant, fetchTenant } = useTenantStore();
  const { user, fetchUser, hasPermission } = useUserStore();

  if (to.name === 'maintenance') return next();

  if (!tenant) fetchTenant();

  if (authenticated && !to.name?.toString().startsWith('auth')) {
    if (!user) {
      await fetchUser();
    } else if (to.meta.permission && !hasPermission(to.meta.permission)) {
      return next({ name: 'dashboard.index' });
    }
  }

  if (!authenticated && !to.name?.toString().startsWith('auth')) {
    setRedirectTo(to.fullPath);
    return next({ name: 'auth.login' });
  }

  const toParts = (to.name as string)?.split('.') || [];
  const fromParts = (from.name as string)?.split('.') || [];
  const isSameScope = toParts[0] === fromParts[0];

  const fromQueryKeys = Object.keys(from.query);
  const toQueryKeys = Object.keys(to.query);
  const hasAllFromQueryKeys = fromQueryKeys.every((key) => toQueryKeys.includes(key));

  if (from.meta.delay) {
    await new Promise((resolve) => setTimeout(resolve, 100));
  }

  if (isSameScope && fromQueryKeys.length && !hasAllFromQueryKeys) {
    return next({ ...to, query: { ...from.query, ...to.query } });
  }

  next();
});

invalidationHooks.push(() => {
  router.push({ name: 'auth.login' });
});

const setTitle = (to: RouteLocationNormalized) => {
  const { tenant, onFetched } = useTenantStore();

  if (tenant) {
    const type = i18n.global.t(`tenant.types.${tenant.type}`);
    document.title = `${type} ${tenant.name} — ${to.meta.title}`;
  } else {
    onFetched(() => setTitle(to));
  }
};

router.afterEach(setTitle);

afterResponseHooks.push((_request, _options, response) => {
  if ([503].includes(response.status)) {
    router.push({ name: 'maintenance' });
  }

  return response;
});
