<script setup lang="ts">
import { ExclamationTriangleIcon } from '@heroicons/vue/24/solid';
import { Color } from '@libero/types/Color';
import Cluster from '@libero/ui-framework/Cluster/Cluster.vue';
import Icon from '@libero/ui-framework/Icon/Icon.vue';
import { classNames } from '@libero/utilities/class-names';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const environment = computed(() => import.meta.env.VITE_ENVIRONMENT);

const shouldRender = computed(() => ['staging', 'acceptance'].includes(environment.value));

const iconColor = computed(() => {
  switch (environment.value) {
    case 'staging':
      return Color.warningBase;
    case 'acceptance':
      return Color.successBase;
    default:
      return undefined;
  }
});
</script>

<template>
  <div v-if="shouldRender" class="environment-warning" :class="classNames({ environment })">
    <Cluster alignItems="center">
      <Icon size="lg" :color="iconColor">
        <ExclamationTriangleIcon />
      </Icon>

      <span>
        <strong>
          {{ t('please-note') }}
        </strong>

        {{ t('environment-warning', { environment }) }}
      </span>
    </Cluster>
  </div>
</template>

<style lang="scss" scoped>
@import '@libero/organisms/EnvironmentWarning/EnvironmentWarning.scss';
</style>
