<script setup lang="ts">
import Cluster from '@libero/ui-framework/Cluster/Cluster.vue';
import Container from '@libero/ui-framework/Container/Container.vue';
import SecondaryNav from '@libero/ui-framework/SecondaryNav/SecondaryNav.vue';
import SecondaryNavItem from '@libero/ui-framework/SecondaryNav/SecondaryNavItem.vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
</script>

<template>
  <Container hasOffset>
    <Cluster>
      <SecondaryNav hasOffset>
        <SecondaryNavItem :to="{ name: 'personal-settings.profile' }">
          {{ t('user.profile') }}
        </SecondaryNavItem>

        <SecondaryNavItem :to="{ name: 'personal-settings.preferences' }">
          {{ t('user.preferences') }}
        </SecondaryNavItem>

        <SecondaryNavItem :to="{ name: 'personal-settings.notifications' }">
          {{ t('user.notifications') }}
        </SecondaryNavItem>

        <SecondaryNavItem :to="{ name: 'personal-settings.security' }">
          {{ t('user.security') }}
        </SecondaryNavItem>
      </SecondaryNav>

      <div :style="{ width: '100%', paddingTop: '1rem' }">
        <RouterView />
      </div>
    </Cluster>
  </Container>
</template>
