<script setup lang="ts">
import { Theme } from '@libero/api-client/theme/types';
import { useThemeStore } from '@libero/hooks/store/useThemeStore';
import ThemeIconBlock from '@libero/organisms/ThemeIcon/ThemeIconBlock.vue';
import Clickable from '@libero/ui-framework/Clickable/Clickable.vue';
import Tooltip from '@libero/ui-framework/Tooltip/Tooltip.vue';

interface Props {
  theme: Theme;
  expanded: boolean;
}

defineProps<Props>();

const themeStore = useThemeStore();
</script>

<template>
  <Tooltip :content="theme.name" :placement="expanded ? 'top' : 'right'">
    <Clickable :onClick="() => themeStore.toggleTheme(theme)">
      <ThemeIconBlock :theme="theme.key" :isDark="themeStore.isThemeActive(theme.id)" />
    </Clickable>
  </Tooltip>
</template>
```
