import { generateScopedName, generateScopedPath } from '@libero/utilities/generic-routing';
import { RouteRecordRaw } from 'vue-router';

export const generateClassificationRoutes = (key: string): RouteRecordRaw[] => [
  {
    path: generateScopedPath('create', 'classification', key),
    name: generateScopedName('classification.create', 'classification', key),
    component: () => import('./views/modals/Create.vue'),
    props: { scope: key },
    meta: {
      delay: true,
      permission: 'classification.store',
    },
  },
  {
    path: generateScopedPath(':id', 'classification', key),
    component: () => import('./views/modals/Show.vue'),
    props: { scope: key },
    children: [
      {
        path: 'properties',
        name: generateScopedName('classification.show', 'classification', key),
        component: () => import('./views/tabs/Properties.vue'),
      },
      {
        path: 'properties/edit',
        name: generateScopedName('classification.edit', 'classification', key),
        component: () => import('./views/tabs/PropertiesEdit.vue'),
      },
      {
        path: 'media',
        name: generateScopedName('classification.media', 'classification', key),
        component: () => import('./views/tabs/Media.vue'),
      },
      {
        path: 'log',
        name: generateScopedName('classification.log', 'classification', key),
        component: () => import('./views/tabs/Log.vue'),
      },
      {
        path: '',
        redirect: { name: generateScopedName('classification.show', 'classification', key) },
      },
    ],
    meta: {
      delay: true,
      permission: 'classification.show',
    },
  },
];
