import type { RouteRecordRaw } from 'vue-router';

export const routes: RouteRecordRaw[] = [
  {
    path: 'integration',
    name: 'integration.index',
    component: () => import('./views/pages/Index.vue'),
    children: [
      {
        path: 'create',
        name: 'integration.create',
        component: () => import('./views/modals/Create.vue'),
        meta: { delay: true },
      },
      {
        path: ':id',
        component: () => import('./views/modals/Show.vue'),
        meta: { delay: true },
        children: [
          {
            path: 'properties',
            name: 'integration.show',
            component: () => import('./views/tabs/Properties.vue'),
          },
          {
            path: 'properties/edit',
            name: 'integration.edit',
            component: () => import('./views/tabs/PropertiesEdit.vue'),
          },
          {
            path: 'log',
            name: 'integration.log',
            component: () => import('./views/tabs/Log.vue'),
          },
        ],
      },
    ],
    meta: {
      title: 'Data instellingen',
      permission: 'integration.index',
    },
  },
];
