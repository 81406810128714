import { jsonApi } from '@libero/utilities/api-client';

import type { EmailSupportRequest } from './types';

const auth = jsonApi('api/support-request');

const emailSupport = (data: EmailSupportRequest): Promise<unknown> => {
  return auth.post(undefined, data);
};

export const supportApi = {
  emailSupport,
};
