<script setup lang="ts">
import {
  ArrowRightOnRectangleIcon,
  ChevronDownIcon,
  Cog8ToothIcon,
  QuestionMarkCircleIcon,
  TableCellsIcon,
  UserIcon,
} from '@heroicons/vue/24/solid';
import Notifications from '@libero/cityview/modules/notification/views/segments/Notifications.vue';
import Search from '@libero/cityview/modules/search/views/segments/Search.vue';
import { useAuthStore } from '@libero/hooks/store/useAuthStore';
import { useUserStore } from '@libero/hooks/store/useUserStore';
import EnvironmentWarning from '@libero/organisms/EnvironmentWarning/EnvironmentWarning.vue';
import { Color } from '@libero/types/Color';
import Avatar from '@libero/ui-framework/Avatar/Avatar.vue';
import Cluster from '@libero/ui-framework/Cluster/Cluster.vue';
import Dropdown from '@libero/ui-framework/Dropdown/Dropdown.vue';
import DropdownAction from '@libero/ui-framework/Dropdown/DropdownAction.vue';
import DropdownBody from '@libero/ui-framework/Dropdown/DropdownBody.vue';
import DropdownFooter from '@libero/ui-framework/Dropdown/DropdownFooter.vue';
import DropdownHeader from '@libero/ui-framework/Dropdown/DropdownHeader.vue';
import Icon from '@libero/ui-framework/Icon/Icon.vue';
import IconButton from '@libero/ui-framework/IconButton/IconButton.vue';
import PageTitle from '@libero/ui-framework/PageTitle/PageTitle.vue';
import Tooltip from '@libero/ui-framework/Tooltip/Tooltip.vue';
import Typography from '@libero/ui-framework/Typography/Typography.vue';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';

import Help from '../Help/Help.vue';

const { t } = useI18n();
const route = useRoute();
const authStore = useAuthStore();
const userStore = useUserStore();
const { hasPermission } = useUserStore();

const dataSettingsModules = ['entity-type', 'attribute-type', 'product', 'export'];
const systemSettingsModules = ['user', 'organisation', 'role', 'contract', 'ticket-subject'];

const dataSettingsModule = computed(() =>
  dataSettingsModules.find((module) => hasPermission(`${module}.index`)),
);

const systemSettingsModule = computed(() =>
  systemSettingsModules.find((module) => hasPermission(`${module}.index`)),
);
</script>

<template>
  <header class="header">
    <PageTitle class="header__title" :title="route.meta.title" />

    <Cluster alignItems="center" :gap="0">
      <Help v-slot="{ openModal }">
        <Tooltip :content="t('help.help')">
          <IconButton appearance="clear" color="secondary" :onClick="openModal">
            <QuestionMarkCircleIcon />
          </IconButton>
        </Tooltip>
      </Help>

      <Search />

      <Notifications />
    </Cluster>

    <div class="header__divider" />

    <Dropdown class="header__dropdown">
      <template #trigger>
        <div class="header__account">
          <Cluster :gap="0.25" alignItems="center">
            <Avatar :avatar="userStore.user?.avatar" :initials="userStore.user?.initials" />

            <Icon :color="Color.gray500">
              <ChevronDownIcon />
            </Icon>
          </Cluster>
        </div>
      </template>

      <template #content>
        <DropdownHeader>
          <Typography tag="h5" type="heading" size="xxs">
            {{ userStore.user?.full_name }}
          </Typography>
        </DropdownHeader>

        <DropdownBody>
          <DropdownAction :to="{ name: 'personal-settings.profile' }">
            <Cluster alignItems="center" :gap="0.5">
              <Icon :color="Color.gray500">
                <UserIcon />
              </Icon>

              {{ t('personal-settings.personal-settings') }}
            </Cluster>
          </DropdownAction>

          <DropdownAction
            v-if="systemSettingsModule"
            :to="{ name: `${systemSettingsModule}.index` }"
          >
            <Cluster alignItems="center" :gap="0.5">
              <Icon :color="Color.gray500">
                <Cog8ToothIcon />
              </Icon>

              {{ t('system-settings.system-settings') }}
            </Cluster>
          </DropdownAction>

          <DropdownAction v-if="dataSettingsModule" :to="{ name: `${dataSettingsModule}.index` }">
            <Cluster alignItems="center" :gap="0.5">
              <Icon :color="Color.gray500">
                <TableCellsIcon />
              </Icon>

              {{ t('data-settings') }}
            </Cluster>
          </DropdownAction>
        </DropdownBody>

        <DropdownFooter>
          <DropdownAction color="error" :onClick="authStore.logout">
            <Cluster alignItems="center" :gap="0.5">
              <Icon :color="Color.errorBase">
                <ArrowRightOnRectangleIcon />
              </Icon>

              {{ t('auth.logout') }}
            </Cluster>
          </DropdownAction>
        </DropdownFooter>
      </template>
    </Dropdown>

    <EnvironmentWarning />
  </header>
</template>

<style lang="scss" scoped>
@import '@libero/cityview/modules/app/views/segments/Header/Header.scss';
</style>
