<script setup lang="ts">
import { useTenantStore } from '@libero/hooks/store/useTenantStore';
import Title from '@libero/organisms/Title/Title.vue';
import Image from '@libero/ui-framework/Image/Image.vue';
import Scrollable from '@libero/ui-framework/Scrollable/Scrollable.vue';
import Stack from '@libero/ui-framework/Stack/Stack.vue';
import Typography from '@libero/ui-framework/Typography/Typography.vue';
import { RouterView, useRoute } from 'vue-router';

const route = useRoute();
const tenantStore = useTenantStore();
</script>

<template>
  <div class="auth-layout-wrapper">
    <div class="auth-layout">
      <Scrollable isAbsolute>
        <Stack>
          <Stack class="auth-layout-header">
            <Title />

            <Typography v-if="!route.meta.hideTitle" tag="h1" type="heading" size="xxl">
              {{ route.meta.title }}
            </Typography>
          </Stack>

          <RouterView />
        </Stack>
      </Scrollable>
    </div>

    <div v-if="tenantStore.tenant" class="auth-layout-image">
      <Image :url="tenantStore.tenant.loginImagePath" :alt="tenantStore.tenant.name" shouldFill />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@libero/cityview/modules/app/views/layouts/AuthLayout/AuthLayout.scss';
</style>
