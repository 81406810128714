import type { RouteRecordRaw } from 'vue-router';

const themesEnabled = import.meta.env.VITE_THEMES_ENABLED === 'true';

export const routes: RouteRecordRaw[] = [
  ...(themesEnabled
    ? [
        {
          path: 'themes',
          name: 'theme.index',
          component: () => import('./views/pages/Index.vue'),
          meta: {
            title: "Thema's",
            permission: 'theme.index',
          },
        },
      ]
    : []),
];
