<script setup lang="ts">
import Typography from '@libero/ui-framework/Typography/Typography.vue';
import { computed } from 'vue';

interface Props {
  title?: string;
}

withDefaults(defineProps<Props>(), {
  title: undefined,
});

const classes = computed(() => {
  return {
    ['page-title']: true,
  };
});
</script>

<template>
  <Typography :class="classes" type="heading" tag="h1">
    <template v-if="title">
      {{ title }}
    </template>

    <template v-else>
      <slot />
    </template>
  </Typography>
</template>
