import { UserSettingsFormat } from '@libero/api-client/auth-user/types';
import { useUserStore } from '@libero/hooks/store/useUserStore';
import { Interval } from '@libero/types/Interval';
import { Range } from '@libero/types/Range';
import dayjs, { Dayjs } from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime);

export const formatDateTime = (
  value: string | number | Dayjs | Date | null | undefined,
): string => {
  const userStore = useUserStore();
  const format = userStore.user?.settings.datetime_format || UserSettingsFormat.Default;

  return value ? dayjs(value).format(format) : '';
};

export const formatDate = (
  value: string | number | Dayjs | Date | null | undefined,
  isShort = false,
): string => {
  const userStore = useUserStore();
  const format =
    userStore.user?.settings.datetime_format.replace(isShort ? 'dddd ' : '', '') ||
    UserSettingsFormat.Default;

  return value ? dayjs(value).format(format.replace(' HH:mm', '')) : '';
};

export const formatDistance = (value: string | number | Dayjs | Date | null = dayjs()): string => {
  return value ? dayjs(value).fromNow() : '';
};

export const now = (): Dayjs => dayjs();

export const formatPeriod = (
  value: string | number | Dayjs | Date | null = dayjs(),
  range: Range,
): string => {
  if (!value) return '';

  const date = dayjs(value);

  switch (range) {
    case Range.Day:
      return date.format('dd DD-MM-YYYY');
    case Range.Week:
      return `${date.year()} week ${date.week()}`;
    case Range.Month:
      return date.format('YYYY-MM');
    default:
    case Range.Year:
      return date.year().toString();
  }
};

export const formatInterval = (
  value: string | number | Dayjs | Date | null = dayjs(),
  interval: Interval,
  range: Range,
): string => {
  if (!value) return '';

  const date = dayjs(value);

  switch (interval) {
    case Interval.Hour:
      return date.format('HH:00');

    case Interval.Month:
      return date.format('MMM');

    case Interval.Week:
      return date.week().toString();

    default:
    case Interval.Day:
      return range === Range.Week ? date.format('dddd') : date.date().toString();
  }
};

export const formatFilter = (
  value: string | number | Dayjs | Date | null = dayjs(),
  interval: Interval,
): string => {
  if (!value) return '';

  const date = dayjs(value);

  const start = date.startOf(interval).format('YYYY-MM-DD');
  const end = date.endOf(interval).format('YYYY-MM-DD');

  return `${start}*${end}`;
};

export const isBetweenTime = (start: string, end: string): boolean => {
  const now = dayjs();
  const startTime = dayjs(start, 'HH:mm');
  const endTime = dayjs(end, 'HH:mm');

  if (endTime.isBefore(startTime)) {
    return now.isAfter(startTime) && now.isBefore(endTime.add(1, 'day'));
  }

  return now.isAfter(startTime) && now.isBefore(endTime);
};
