<script setup lang="ts">
import { BookOpenIcon, ChatBubbleLeftRightIcon } from '@heroicons/vue/24/solid';
import Support from '@libero/cityview/modules/support/views/modals/Support.vue';
import { Color } from '@libero/types/Color';
import Block from '@libero/ui-framework/Block/Block.vue';
import Button from '@libero/ui-framework/Button/Button.vue';
import Cluster from '@libero/ui-framework/Cluster/Cluster.vue';
import Icon from '@libero/ui-framework/Icon/Icon.vue';
import Modal from '@libero/ui-framework/Modal/Modal.vue';
import ModalContent from '@libero/ui-framework/Modal/ModalContent.vue';
import Stack from '@libero/ui-framework/Stack/Stack.vue';
import Typography from '@libero/ui-framework/Typography/Typography.vue';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const isOpen = ref(false);

const toggleVisible = () => {
  isOpen.value = !isOpen.value;
};
</script>

<template>
  <slot :openModal="toggleVisible" />

  <Modal :isOpen="isOpen" width="42rem" :title="t('help.help-title')" :onCancel="toggleVisible">
    <ModalContent>
      <Cluster>
        <Block :verticalSpacing="2" :backgroundColor="Color.gray050" borderRadius="md">
          <Stack alignItems="center" :gap="1.5">
            <Icon size="xxxl">
              <BookOpenIcon />
            </Icon>

            <Typography size="lg" align="center" isDark>
              {{ t('help.knowledge-base-description') }}
            </Typography>

            <Cluster>
              <Button href="https://helpdesk.cityview.nl/" shouldOpenBlank>
                {{ t('help.knowledge-base') }}
              </Button>
            </Cluster>
          </Stack>
        </Block>

        <Block :verticalSpacing="2" :backgroundColor="Color.gray050" borderRadius="md">
          <Stack alignItems="center" :gap="1.5">
            <Icon size="xxxl">
              <ChatBubbleLeftRightIcon />
            </Icon>

            <Typography size="lg" align="center" isDark>
              {{ t('help.contact-support-description') }}
            </Typography>

            <Cluster>
              <Support v-slot="{ openModal }" :onSuccess="toggleVisible">
                <Button :onClick="openModal">
                  {{ t('help.contact-support') }}
                </Button>
              </Support>
            </Cluster>
          </Stack>
        </Block>
      </Cluster>
    </ModalContent>
  </Modal>
</template>
