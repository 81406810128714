<script lang="ts" setup>
import { DocumentDuplicateIcon } from '@heroicons/vue/24/solid';
import { ReplicationNotification } from '@libero/api-client/notification/types';
import { progressApi } from '@libero/api-client/progress/api';
import { useUserStore } from '@libero/hooks/store/useUserStore';
import Progress from '@libero/ui-framework/Progress/Progress.vue';
import Stack from '@libero/ui-framework/Stack/Stack.vue';
import Typography from '@libero/ui-framework/Typography/Typography.vue';
import { UnsubscribeCallback } from '@libero/utilities/echo-client';
import { omit } from 'lodash';
import { onMounted } from 'vue';
import { onUnmounted } from 'vue';
import { ref } from 'vue';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

import { Props } from './BaseNotification.vue';
import BaseNotification from './BaseNotification.vue';

const props = defineProps<Props>();

const { t } = useI18n();
const userStore = useUserStore();

const notification = computed(() => props.notification as ReplicationNotification);

const unsubscribe = ref<UnsubscribeCallback>();
const current = ref(0);

onMounted(() => {
  if (!userStore.user) return;

  unsubscribe.value = progressApi.subscribeContainerReplication(userStore.user.id, (progress) => {
    current.value = progress.container_ids.length;

    if (progress.container_ids.length === notification.value.data.count) {
      setTimeout(() => props.onClose?.(), 500);
    }
  });
});

onUnmounted(() => {
  unsubscribe.value?.();
});
</script>

<template>
  <BaseNotification v-bind="omit(props, 'onClose')" :notification="notification">
    <template #icon>
      <DocumentDuplicateIcon />
    </template>

    <Stack :gap="0.25">
      <Typography isDark>
        {{ t('notification.replication-started') }}
      </Typography>

      <Progress :percentage="Math.floor((current / notification.data.count) * 100)" />
    </Stack>
  </BaseNotification>
</template>
