import type { Theme } from '@libero/api-client/theme/types';
import { defineStore } from 'pinia';
import { computed, type Ref, ref } from 'vue';

import { useTenantStore } from './useTenantStore';

interface Store {
  themes: Ref<Theme[]>;
  activeThemes: Ref<number[]>;
  toggleTheme: (theme: Theme) => void;
  isThemeActive: (id: string | number) => boolean;
}

export const useThemeStore = defineStore('theme', (): Store => {
  const tenantStore = useTenantStore();

  const themes = computed(() => tenantStore.tenant?.themes || []);
  const activeThemes = ref<number[]>([]);

  const toggleTheme = (theme: Theme) => {
    const index = activeThemes.value.indexOf(theme.id);

    if (index === -1) {
      activeThemes.value.push(theme.id);
    } else {
      activeThemes.value.splice(index, 1);
    }
  };

  const isThemeActive = (id: string | number) => {
    return !!activeThemes.value.find((i) => i === id);
  };

  return {
    themes,
    activeThemes,
    toggleTheme,
    isThemeActive,
  };
});
