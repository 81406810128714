import { useAuthStore } from '@libero/hooks/store/useAuthStore';
import type { RouteRecordRaw } from 'vue-router';

export const routes: RouteRecordRaw[] = [
  {
    path: 'login',
    name: 'auth.login',
    component: () => import('./views/pages/Login.vue'),
    meta: {
      title: 'Inloggen',
    },
  },
  {
    path: 'register',
    name: 'auth.register',
    component: () => import('./views/pages/Register.vue'),
    meta: {
      title: 'Registreren',
    },
  },
  {
    path: 'register/email-verification',
    name: 'auth.email-verification',
    component: () => import('./views/pages/EmailVerification.vue'),
    meta: {
      title: 'Controleer je inbox',
    },
  },
  {
    path: 'forgot-password',
    name: 'auth.forgot-password',
    component: () => import('./views/pages/ForgotPassword.vue'),
    meta: {
      title: 'Wachtwoord vergeten',
    },
  },
  {
    path: 'reset-password/:token',
    name: 'auth.reset-password',
    component: () => import('./views/pages/ResetPassword.vue'),
    meta: {
      title: 'Wachtwoord veranderen',
    },
  },
  {
    path: 'verify-two-factor',
    name: 'auth.verify-two-factor',
    component: () => import('./views/pages/VerifyTwoFactor.vue'),
    meta: {
      title: 'Verifieer twee-factor authenticatie',
    },
  },
  {
    path: 'verify',
    name: 'auth.verify',
    component: () => import('./views/pages/Verify.vue'),
    meta: {
      title: 'Login controle',
    },
  },
  {
    path: 'terms',
    name: 'auth.terms',
    component: () => import('./views/pages/Terms.vue'),
    beforeEnter: (to, from, next): void => {
      const { authenticated } = useAuthStore();

      if (!authenticated) {
        return next({ name: 'auth.login' });
      }

      next();
    },
    meta: {
      title: 'Privacybeleid',
      hideTitle: true,
    },
  },
];
