<script setup lang="ts">
import { classNames } from '@libero/utilities/class-names';

import { useSidebarState } from './useSidebarState';

const { isExpanded, toggleSidebarSave } = useSidebarState();
</script>

<template>
  <div class="sidebar" :class="classNames({ isExpanded })">
    <slot :isExpanded="isExpanded" :toggleSidebar="toggleSidebarSave" />
  </div>
</template>

<style lang="scss" scoped>
@import '@libero/ui-framework/Sidebar/Sidebar.scss';
</style>
