import type { RouteRecordRaw } from 'vue-router';

export const routes: RouteRecordRaw[] = [
  {
    path: 'view',
    name: 'view.index',
    component: () => import('./views/pages/Index.vue'),
    meta: {
      title: 'Weergaven',
    },
  },
];
