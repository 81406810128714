import { generateContainerRoutes } from '@libero/cityview/modules/container/generate-routes';
import { generateTicketRoutes } from '@libero/cityview/modules/ticket/generate-routes';
import type { RouteRecordRaw } from 'vue-router';

const energyEnabled = import.meta.env.VITE_ENERGY_ENABLED === 'true';

export const routes: RouteRecordRaw[] = [
  ...(energyEnabled
    ? [
        {
          path: 'energy',
          name: 'energy.index',
          component: () => import('./views/pages/Index.vue'),
          children: [...generateContainerRoutes('energy'), ...generateTicketRoutes('energy')],
          meta: {
            title: 'Energie',
            permission: 'energy.index',
          },
        },
      ]
    : []),
];
