<script lang="ts" setup>
import { InboxIcon } from '@heroicons/vue/24/solid';
import { TicketNotification } from '@libero/api-client/notification/types';
import Typography from '@libero/ui-framework/Typography/Typography.vue';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

import BaseNotification, { Props } from './BaseNotification.vue';

const props = defineProps<Props>();

const { t } = useI18n();

const notification = computed(() => props.notification as TicketNotification);
</script>

<template>
  <BaseNotification
    v-bind="props"
    :notification="notification"
    :to="{ name: 'ticket.show', params: { id: notification.data.ticket_id } }"
  >
    <template #icon>
      <InboxIcon />
    </template>

    <Typography>
      <Typography isDark bold>
        {{ t(`ticket.ticket`) }} {{ notification.data.ticket_number }}
      </Typography>

      {{ t('notification.ticket-action-owner') }}
    </Typography>
  </BaseNotification>
</template>
