import { generateClassificationRoutes } from '@libero/cityview/modules/classification/generate-routes';
import { generateFileRoutes } from '@libero/cityview/modules/file/generate-routes';
import { generateTicketRoutes } from '@libero/cityview/modules/ticket/generate-routes';
import { generateUserRoutes } from '@libero/cityview/modules/user/generate-routes';
import type { RouteRecordRaw } from 'vue-router';

export const routes: RouteRecordRaw[] = [
  {
    path: 'e-safety',
    name: 'e-safety.index',
    component: () => import('./views/pages/Index.vue'),
    children: [
      ...generateUserRoutes('e-safety'),
      ...generateTicketRoutes('e-safety'),
      ...generateFileRoutes('e-safety'),
      ...generateClassificationRoutes('e-safety'),
    ],
    meta: {
      title: 'E-veiligheid',
      permission: 'ticket.index',
    },
  },
];
