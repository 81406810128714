import { generateContainerRoutes } from '@libero/cityview/modules/container/generate-routes';
import { generateFileRoutes } from '@libero/cityview/modules/file/generate-routes';
import { generateTicketRoutes } from '@libero/cityview/modules/ticket/generate-routes';
import type { RouteRecordRaw } from 'vue-router';

export const routes: RouteRecordRaw[] = [
  {
    path: 'sensorregister',
    name: 'sensor-registry.index',
    component: () => import('./views/pages/Index.vue'),
    children: [
      ...generateContainerRoutes('sensor-registry'),
      ...generateTicketRoutes('sensor-registry'),
      ...generateFileRoutes('sensor-registry'),
    ],
    meta: {
      title: 'Sensorregister',
      permission: 'sensor-registry.index',
    },
  },
];
