<script lang="ts" setup>
import { MagnifyingGlassIcon } from '@heroicons/vue/24/solid';
import SearchContainer from '@libero/cityview/modules/search/components/Search/SearchContainer.vue';
import IconButton from '@libero/ui-framework/IconButton/IconButton.vue';
import Tooltip from '@libero/ui-framework/Tooltip/Tooltip.vue';
import { onMounted } from 'vue';
import { onUnmounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const isOpen = ref(false);

const toggleOpen = () => {
  isOpen.value = !isOpen.value;
};

const handleClose = () => {
  isOpen.value = false;
};

const handleKeyDown = (event: KeyboardEvent) => {
  if (event.ctrlKey && event.code === 'Space') {
    isOpen.value = true;
  }

  if (event.key === 'Escape' && isOpen.value) {
    isOpen.value = false;
  }
};

onMounted(() => {
  document.addEventListener('keydown', handleKeyDown);
});

onUnmounted(() => {
  document.addEventListener('keydown', handleKeyDown);
});
</script>

<template>
  <Tooltip :content="t('search.search')">
    <IconButton appearance="clear" color="secondary" :onClick="toggleOpen">
      <MagnifyingGlassIcon />
    </IconButton>
  </Tooltip>

  <SearchContainer :isOpen="isOpen" :onClose="handleClose" />
</template>
