<script setup lang="ts">
import { CalendarIcon, XMarkIcon } from '@heroicons/vue/24/solid';
import InputError from '@libero/ui-framework/InputError/InputError.vue';
import Stack from '@libero/ui-framework/Stack/Stack.vue';
import { DatePicker as AntDatePicker } from 'ant-design-vue';
import dayjs, { type Dayjs } from 'dayjs';
import { ref } from 'vue';
import { ComponentPublicInstance } from 'vue';
import { computed, onMounted, onUnmounted } from 'vue';
import { useI18n } from 'vue-i18n';

interface Props {
  id: string;
  name: string;
  value?: string | dayjs.Dayjs | null;
  size?: 'xs' | 'sm' | 'md' | 'lg';
  preset?: 'date' | 'datetime' | 'year';
  disabled?: boolean;
  minWidth?: string;
  isClearable?: boolean;
  placeholder?: string;
  error?: string;
  isDirty?: boolean;
  disabledDate?: (date: Dayjs) => boolean;
  onUpdate?: (value: string | dayjs.Dayjs) => void;
  onMount?: (onFocus?: () => void, shouldFocus?: boolean) => void;
  onUnmount?: () => void;
}

const props = withDefaults(defineProps<Props>(), {
  value: '',
  size: 'md',
  preset: 'datetime',
  disabled: false,
  minWidth: undefined,
  placeholder: undefined,
  error: undefined,
  disabledDate: undefined,
  onUpdate: undefined,
  onMount: undefined,
  onUnmount: undefined,
});

const { t } = useI18n();

const datePicker = ref<ComponentPublicInstance<typeof AntDatePicker>>();

const format = computed(() => {
  switch (props.preset) {
    case 'year':
      return 'YYYY';
    case 'datetime':
      return 'DD-MM-YYYY HH:mm:ss';
    default:
      return 'DD-MM-YYYY';
  }
});

const getRawValue = (date: Dayjs) => {
  switch (props.preset) {
    case 'year':
      return date.format('YYYY');
    case 'datetime':
      return date.toISOString();
    default:
      return date.format('YYYY-MM-DD');
  }
};

const picker = computed((): 'year' | undefined => {
  switch (props.preset) {
    case 'year':
      return 'year';
    default:
      return undefined;
  }
});

const classes = computed(() => {
  return {
    ['size-' + props.size]: true,
    ['is-dirty']: props.isDirty,
  };
});

const placeholder = computed(() => {
  if (props.placeholder) return props.placeholder;
  return props.disabled && !props.value ? '-' : undefined;
});

const handleUpdate = (value: string | Dayjs) => {
  if (value) {
    const date = dayjs.isDayjs(value) ? value : dayjs(value);
    return props.onUpdate?.(getRawValue(date));
  }

  props.onUpdate?.(value);
};

onMounted(() => {
  props.onMount?.(datePicker.value?.focus, props.disabled);
});

onUnmounted(() => {
  props.onUnmount?.();
});
</script>

<template>
  <Stack :gap="0">
    <AntDatePicker
      :id="id"
      ref="datePicker"
      :name="name"
      :class="classes"
      :value="value ? dayjs(value) : undefined"
      :format="format"
      :picker="picker"
      :showTime="preset === 'datetime' ? { format: 'HH:mm' } : undefined"
      :disabled="disabled"
      :placeholder="placeholder || t(`select-${preset}`)"
      :allowClear="isClearable"
      :disabledDate="disabledDate"
      :style="{ minWidth }"
      :onUpdate:value="handleUpdate"
    >
      <template #suffixIcon>
        <CalendarIcon />
      </template>

      <template #clearIcon>
        <XMarkIcon />
      </template>
    </AntDatePicker>

    <InputError :message="error" />
  </Stack>
</template>

<style lang="scss" scoped>
@import '@libero/ui-framework/DatePicker/DatePicker.scss';
</style>
