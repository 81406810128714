import { authApi } from '@libero/api-client/auth/api';
import type { LoginRequest, LoginResponse } from '@libero/api-client/auth/types';
import { twoFactorApi } from '@libero/api-client/two-factor/api';
import { TwoFactorVerifyRequest } from '@libero/api-client/two-factor/types';
import { useLocalStorage } from '@libero/hooks/useLocalStorage';
import { queryClient } from '@libero/plugins/query';
import { afterResponseHooks } from '@libero/utilities/api-client';
import { message } from 'ant-design-vue';
import { defineStore } from 'pinia';
import { type Ref } from 'vue';
import { useRouter } from 'vue-router';
import { RouterLocation } from 'vue-router';

import { useUserStore } from './useUserStore';

interface Store {
  authenticated: Ref<boolean | undefined>;
  redirectTo: Ref<RouterLocation | undefined>;
  setRedirectTo: (route: RouterLocation) => void;
  redirect: () => void;
  login: (data: LoginRequest) => Promise<LoginResponse | undefined>;
  logout: () => void;
  providerVerify: () => Promise<LoginResponse | undefined>;
  twoFactorVerify: (data: TwoFactorVerifyRequest) => Promise<LoginResponse | undefined>;
  reset: (invalidated?: boolean) => void;
}

type AfterErrorHook = () => void;

export const invalidationHooks: AfterErrorHook[] = [];

export const useAuthStore = defineStore('auth', (): Store => {
  const router = useRouter();
  const userStore = useUserStore();

  const [redirectTo, setRedirectTo] = useLocalStorage<RouterLocation>('redirect-to');
  const [authenticated, setAuthenticated] = useLocalStorage('authenticated', false);
  const [, setBearerToken] = useLocalStorage<string>('bearer-token');

  const redirect = () => {
    router.push(redirectTo.value || '/');
    setRedirectTo(undefined);
  };

  const loginSuccess = (response: LoginResponse) => {
    if (response.access_token) {
      authenticated.value = true;

      setAuthenticated(true);
      setBearerToken(`Bearer ${response.access_token}`);
    }

    return response;
  };

  const login = async (data: LoginRequest) => {
    return authApi
      .login(data)
      .then(loginSuccess)
      .catch((error) => {
        invalidationHooks.forEach((hook) => hook());
        throw error;
      });
  };

  const reset = () => {
    queryClient.clear();
    userStore.reset();

    setAuthenticated(false);
    setBearerToken(undefined);

    invalidationHooks.forEach((hook) => hook());
  };

  const logout = (invalidated = false) => {
    if (!invalidated) {
      authApi.logout();
    }

    reset();
  };

  const providerVerify = async () => {
    if (document.location.search) {
      return authApi
        .providerVerify(document.location.search)
        .then(loginSuccess)
        .catch((error) => {
          invalidationHooks.forEach((hook) => hook());
          message.error(error.response?.data?.message || error.message);
          throw error;
        });
    }

    invalidationHooks.forEach((hook) => hook());
  };

  const twoFactorVerify = async (data: TwoFactorVerifyRequest) => {
    return twoFactorApi.verify(data).then(loginSuccess);
  };

  afterResponseHooks.push((_request, _options, response) => {
    if ([401].includes(response.status)) {
      reset();
    }

    if ([451].includes(response.status)) {
      router.push({ name: 'auth.terms' });
    }

    return response;
  });

  return {
    authenticated,
    redirectTo,
    setRedirectTo,
    redirect,
    login,
    logout,
    providerVerify,
    twoFactorVerify,
    reset,
  };
});
